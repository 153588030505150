
const langs = ['en','fr','es','de','pt','it','ru','sv','el','nb','pl','iw','ar','nl','tr','ja','zh_CN','zh_TW','hu','vi','ko', 'ja','fi','cs','da','ro','lt','ms','th'];
export const langsMap = {
    en:"English",
    fr:"French",
    es:'Spanish',
    de:"German",
    pt:"Portuguese",
    it:"Italian",
    ru:"Russian",
    tr:'Turkish',
    sv:"Swedish",
    el:"Greek",
    nb:"Norwegian",
    pl:"Polish",
    iw:"Hebrew",
    ar:"Arabic",
    nl:"Dutch",
    'zh_CN':"Chinese (Simplified)",
    'zh_TW':"Chinese (Traditional)",
    hu:'Hungarian',
    vi:'Vietnamese',
    ko: 'Korean',
    ja:'Japanese',
    fi:'Finnish',
    cs:'Czech',
    da: 'Danish',
    ro:'Romanian',
    lt:'Lithuanian',
    ms:'Malay',
    th:'Thai'
}

const loadLocale = (locale: string) => fetch(`/locales/${locale}.json`).then((d)=>d.json()).then((x)=>x.default);
const LangsLoader = Object
    .keys(langsMap).reduce((acc, key) => {
        acc[key] = () => loadLocale(key);
        return acc;
},{});
// const LangsLoader = {
//     en: () => fetch('/locales/en.json').then((d)=>d.json()),
//     fr: () => import('./fr').then((d)=>d.default),
//     es: () => import('./es').then((d)=>d.default),
//     de: () => import('./de').then((d)=>d.default),
//     pt: () => import('./pt').then((d)=>d.default),
//     it: () => import('./it').then((d)=>d.default),
//     ru: () => import('./ru').then((d)=>d.default),
//     sv: () => import('./sv').then((d)=>d.default),
//     el: () => import('./el').then((d)=>d.default),
//     nb: () => import('./nb').then((d)=>d.default),
//     pl: () => import('./pl').then((d)=>d.default),
//     iw: () => import('./iw').then((d)=>d.default),
//     ar: () => import('./ar').then((d)=>d.default),
//     nl: () => import('./nl').then((d)=>d.default),
//     'zh_CN': () => import('./zh_CN').then((d)=>d.default),
//     'zh_TW': () => import('./zh_TW').then((d)=>d.default),
//     ja: () => import('./ja').then((d)=>d.default),
//     tr:()=>import('./tr').then((d)=>d.default),
//     hu:()=>import('./hu').then((d)=>d.default),
//     vi:()=>import('./vi').then((d)=>d.default),
//     ko:()=>import('./ko').then((d)=>d.default),
//     fi:()=>import('./fi').then((d)=>d.default),
//     cs:()=>import('./cs').then((d)=>d.default),
//     da:()=>import('./da').then((d)=>d.default),
//     ro:()=>import('./ro').then((d)=>d.default),
//     lt:()=>import('./lt').then((d)=>d.default),
//     th:()=>import('./th').then((d)=>d.default),
//     ms:()=>import('./ms').then((d)=>d.default),
//
// };

// const IntlDataLoader = {
//     en: () => import('intl/locale-data/jsonp/en.js').then((d)=>d.default),
//     fr: () => import('intl/locale-data/jsonp/fr.js').then((d)=>d.default),
//     es: () => import('intl/locale-data/jsonp/es.js').then((d)=>d.default),
//     de: () => import('intl/locale-data/jsonp/de.js').then((d)=>d.default),
//     pt: () => import('intl/locale-data/jsonp/pt.js').then((d)=>d.default),
//     it: () => import('intl/locale-data/jsonp/it.js').then((d)=>d.default),
//     ru: () => import('intl/locale-data/jsonp/ru.js').then((d)=>d.default),
//     sv: () => import('intl/locale-data/jsonp/sv.js').then((d)=>d.default),
//     el: () => import('intl/locale-data/jsonp/el.js').then((d)=>d.default),
//     nb: () => import('intl/locale-data/jsonp/nb.js').then((d)=>d.default),
//     pl: () => import('intl/locale-data/jsonp/pl.js').then((d)=>d.default),
//     iw: () => import('intl/locale-data/jsonp/en.js').then((d)=>d.default),
//     ar: () => import('intl/locale-data/jsonp/ar.js').then((d)=>d.default),
//     nl: () => import('intl/locale-data/jsonp/nl.js').then((d)=>d.default),
//     'zh_CN': () => import('intl/locale-data/jsonp/zh.js').then((d)=>d.default),
//     'zh_TW': () => import('intl/locale-data/jsonp/zh-Hant-TW.js').then((d)=>d.default),
//     ja: () => import('./ja').then((d)=>d.default),
//     tr:()=>import('./tr').then((d)=>d.default),
//     hu:()=>import('./hu').then((d)=>d.default),
//     vi:()=>import('intl/locale-data/jsonp/vi.js').then((d)=>d.default),
//     ko:()=>import('intl/locale-data/jsonp/ko.js').then((d)=>d.default),
//     cs:()=>import('intl/locale-data/jsonp/cs.js').then((d)=>d.default),
//     da:()=>import('intl/locale-data/jsonp/da.js').then((d)=>d.default),
//     ro:()=>import('intl/locale-data/jsonp/ro.js').then((d)=>d.default),
//     lt:()=>import('intl/locale-data/jsonp/lt.js').then((d)=>d.default),
//     th:()=>import('intl/locale-data/jsonp/th.js').then((d)=>d.default),
//     ms:()=>import('intl/locale-data/jsonp/ms.js').then((d)=>d.default)
// }

export function getLocale(locale = 'en', localeAuto){
    let loc = locale;
    try {
        if (langs.indexOf(localStorage.getItem('locale') || '') > -1) {
            return localStorage.getItem('locale')
        }
    }catch(e){

    }
    if (localeAuto){
        let userLang = navigator.language;
        if (userLang) {
            const parts = userLang.split('-');
            if (parts.length > 0) {
                const language = parts[0];
                if (langs.indexOf(language) > -1) {
                    loc = language;
                }
            }
        }
    }
    return loc;
}

function UriLoader(localeUri){
    if(localeUri) {
        return fetch(localeUri).then((res) => res ? res.json() : {}).catch(e=>{})
    }else{
        return Promise.resolve({})
    }
}

export function PrimeIntl(locale: string, localeUri: string, mLocaleMap: any){
    const messages = {};
    let luri = mLocaleMap[locale];
    if(!luri && localeUri) {
        const parts = localeUri.split("/");
        const file = parts[parts.length-1];
        if(file.startsWith(locale)){
            luri = localeUri
        }
    }
    const promises = [
        LangsLoader[locale]().catch((e)=>{
            console.log('error', e)
        }),
        UriLoader(luri)]//, IntlDataLoader[locale]()];
    return Promise.all(promises).then((result) => {
        console.log(result[1])
        messages[locale] = {...result[0], ...result[1]};
    }).then((r) => messages);
}

